body {
  margin: 0;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

* {
  line-height: 1;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* button:disabled {
  cursor: not-allowed;
} */

input {
  border-radius: 0;
  text-indent: 0;
  padding: 0;
}

/* input[type="search"] {
  -webkit-appearance: none;
} */

a,
button,
select,
input {
  outline: none;
  font-size: 1rem;
  font-weight: 600;
}

html,
body {
  min-height: 100%;
}

body .SingleDatePicker,
body .SingleDatePickerInput,
body .DateInput {
  width: 100%;
}

body .DateInput_input {
  padding: 0;
  height: 64px;
  line-height: 64px;
  border-bottom: 2px solid #e7e7e7;
  width: 100%;
}
