@keyframes slideUp {
  from {
    transform: translateY(64px);
  }

  to {
    transform: translateY(0);
  }
}

.slideUp {
  animation-name: slideUp;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(64px);
  }
}

.slideDown {
  animation-name: slideDown;
}

/* NEW STYLEs */

@keyframes slideUpNew {
  from {
    transform: translateY(98px);
  }

  to {
    transform: translateY(0);
  }
}

.slideUpNew {
  animation-name: slideUpNew 0.6s ease-in both;
}

@keyframes slideDownNew {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(98px);
  }
}

.slideDownNew {
  animation-name: slideDownNew 0.6s ease-in both;
}
